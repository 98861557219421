import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contents: {
    marginTop: theme.spacing(3),
  },
}));

export const NotFoundPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" className={classes.contents}>
      <Typography variant="body1">不正なアドレスです</Typography>
    </Box>
  );
};
