import React, { useState } from 'react';
import { Box, CircularProgress, Theme, Typography } from '@material-ui/core';

import { GetUserCouponUseCaseResult } from '../../ecosystems/hooks/get-coupon';
import { useClearCouponLight } from '../../ecosystems/hooks/clear-coupon-light';
import { MasterHead } from '../molcules/MasterHead';
import { useTheme } from '@material-ui/core/styles';
import {
  SmartCheckLightExpiredTemplateExpiredTemplateWithTheme,
  SmartCheckLightOutOfDisplayTemplateWithTheme,
  SmartCheckLightUnavailableTemplateWithTheme,
  SmartCheckLightTemplate,
  SmartCheckLightViewProps,
  LayoutPattern,
} from '@sbgift-coupon/use';
import { useForceCouponLight } from '../../ecosystems/hooks/force-coupon-light';

/**
 * SmartCheckLight用画面
 * SmartCheckコンシューマ向け画面では、画面遷移があるため各ステートを管理するコンポーネント
 * ステートによって、画面を切り替える（URLは変更されない）
 */
export const SmartCheckLight: React.FC<GetUserCouponUseCaseResult> = (props) => {
  const theme = useTheme<Theme>();

  // 確認画面を表示するかどうか
  const [confirm, setConfirm] = useState<boolean>(false);

  // カウントダウンが終了したかどうか
  const [countDownComplete, setCountDownComplete] = useState<boolean>(false);

  // APIから取得するクーポンデータ
  const [coupon, setCoupon] = useState<GetUserCouponUseCaseResult>(props);

  // SmartCheckLight消込用API
  const { loading: clearLoading, error: clearError, post: clearPost } = useClearCouponLight(props.barcode.barcodeValue);

  // SmartCheckLight強制終了API
  const { loading: forceLoading, error: forceError, post: forcePost } = useForceCouponLight(props.barcode.barcodeValue);

  // 使用するボタンをクリックしたとき
  const onUseSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setConfirm(true);
  };

  // 確認画面で使用するボタンをクリックしたとき
  const onConfirmSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setConfirm(false);

    // 消込後に最新のクーポンデータを取得
    const clearedData = await clearPost({
      status: 'USED',
    });
    setCoupon(clearedData);
  };

  // 強制終了ボタンをクリックしたとき
  const onForceSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    // 強制終了後に最新のクーポンデータを取得
    const forcedData = await forcePost({
      status: 'USED',
    });
    setCoupon(forcedData);
  };

  // キャンセルボタンをクリックしたとき
  const onCancelClick = (): void => {
    setConfirm(false);
  };

  // 強制終了後 or 消込済み or props
  const viewProps: SmartCheckLightViewProps = {
    layoutPattern: props.layoutPattern as LayoutPattern,
    meta: { metaTags: coupon.meta, couponName: coupon.couponName },
    data: {
      organizationId: coupon.organizationId,
      couponMasterId: coupon.couponMasterId,
      id: coupon.id,
      applyWith: coupon.applyWith,
      couponCode: coupon.couponCode,
      couponName: {
        couponName: coupon.couponName,
        couponNameHtml: coupon.couponNameHtml,
        couponNameTextColor: coupon.couponNameTextColor,
        couponNameBandColor: coupon.couponNameBandColor,
      },
      organizationName: {
        companyName: coupon.organizationName,
        couponIssuerTextColor: coupon.couponIssuerTextColor,
        couponIssuerBandColor: coupon.couponIssuerBandColor,
      },
      companyImageUrl: coupon.companyImageUrl,
      barcode: coupon.barcode,
      description: {
        validityStartDateTime: coupon.validityStartDateTime,
        validityEndDateTime: coupon.validityEndDateTime,
        description: coupon.description,
        information: coupon.information,
        mapUrl: coupon.mapUrl,
        telNumber: coupon.telNumber,
      },
      smartCheckSetting: coupon.smartCheckSetting,
      smartCheckImageUrl: coupon.smartCheckImageUrl,
      smartCheckUsedAt: coupon.smartCheckUsedAt,
    },
    available: {
      available: coupon.available,
      displayable: coupon.displayable,
      status: coupon.status,
      validity: coupon.validity,
    },
    handlers: {
      onUseSubmit,
      onConfirmSubmit,
      onCancelClick,
      onForceSubmit,
    },
    confirm,
    smartCheckCountDown: {
      countDownNow: coupon.smartCheckCountDownNow,
      countDownComplete,
      setCountDownComplete,
    },
  };

  if (coupon.productImageUrl) {
    viewProps.data.productImage = {
      couponName: coupon.couponName,
      used: false,
      expired: coupon.validity === 'after',
      imageUrl: coupon.productImageUrl,
    };
  }

  // エラー
  if (forceError) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body1">クーポンの表示に失敗しました</Typography>
      </Box>
    );
  }

  // 消し込みエラー
  if (clearError) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body1">
          クーポンの消し込みに失敗しました
          <br />
          ページを再度読み込んでください
        </Typography>
      </Box>
    );
  }

  // 消込API実行中
  if (clearLoading || forceLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const { available, meta, data } = viewProps;

  // 利用不可
  if (!available.available || available.status === 'CANCELED') {
    return (
      <>
        <MasterHead {...meta} />
        <SmartCheckLightUnavailableTemplateWithTheme theme={theme} {...data} />
      </>
    );
  }

  // 表示期間後
  if (available.displayable !== 'display') {
    const props = { ...data, displayable: available.displayable };
    return (
      <>
        <MasterHead {...meta} />
        <SmartCheckLightOutOfDisplayTemplateWithTheme theme={theme} {...props} />
      </>
    );
  }

  // 有効期限切れ
  if (available.validity === 'after') {
    return (
      <>
        <MasterHead {...meta} />
        <SmartCheckLightExpiredTemplateExpiredTemplateWithTheme theme={theme} {...data} />
      </>
    );
  }

  return (
    <>
      <MasterHead {...viewProps.meta} />
      <SmartCheckLightTemplate {...viewProps} />
    </>
  );
};
