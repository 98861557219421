import React from 'react';
import { useParams } from 'react-router-dom';
import { PathParams } from '../App';
import { useGetCoupon } from './hooks/get-coupon';
import { Box, CircularProgress, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PosBarcode12 } from '../components/organisms/PosBarcode12';
import { SmartCheckLight } from '../components/organisms/SmartCheckLight';
import { NotFoundPage } from './NotFoundPage';
import { SmartCheck } from '../components/organisms/SmartCheck';

const uuidC4Regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

const useStyles = makeStyles((theme) => ({
  contents: {
    marginTop: theme.spacing(3),
  },
}));

/**
 * コンシューマ向けクーポンページ
 * このページコンポーネントから applyWith の値に応じて表示するクーポンテンプレートを決定する
 */
export const CouponPage: React.FC = () => {
  const classes = useStyles();
  const { couponId } = useParams<PathParams>();

  // クーポンIDがUUIDV4でなければここで弾く
  if (!uuidC4Regex.test(couponId)) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" className={classes.contents}>
        <Typography variant="body1">不正なアドレスです</Typography>
      </Box>
    );
  }

  // 共通のクーポン取得APIを呼び出し、applyWithの値で表示するクーポン画面を切り替える
  // コンシューマ画面上はクーポンのIDしかわからないため、APIは共通
  const { loading, error, coupon } = useGetCoupon(couponId);

  if (loading || !coupon) {
    return (
      <Container maxWidth="xs">
        <Box display="flex" alignItems="center" justifyContent="center" className={classes.contents}>
          <CircularProgress />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" className={classes.contents}>
          <Typography variant="h4">読込中</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" className={classes.contents}>
          <Typography variant="body1">
            読込中のまま画面が表示されない場合は、ブラウザのバージョンを最新にしていただくか、
            他のブラウザで再度アクセスいただきますようお願い致します。
          </Typography>
        </Box>
      </Container>
    );
  }
  if (error) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" className={classes.contents}>
        <Typography variant="body1">クーポンの表示に失敗しました</Typography>
      </Box>
    );
  }

  // applyWithで表示するテンプレートを切り替える
  switch (coupon.applyWith) {
    case 'barcode':
      return <PosBarcode12 {...coupon} />;
    case 'smartCheck':
      // TODO
      return <SmartCheck {...coupon} />;
    case 'smartCheckLight':
      return <SmartCheckLight {...coupon} />;
    default:
      return <NotFoundPage />;
  }
};
