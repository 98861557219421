import { CachePolicies, FetchData, Res, useFetch } from 'use-http';
import Bugsnag from '@bugsnag/js';

export type FetchResponse = {
  barcodeValue: string;
  couponName: string;
  memberShopCode: string;
  clearingCode: string;
  shopName: string;
};
export type ClearCouponLight = {
  loading: boolean;
  error?: Error;
  post: FetchData;
  data?: FetchResponse;
  response: Res<FetchResponse>;
};

/**
 * SmartCheckLightのクーポンを消し込む
 * @param barcode
 */
export function useConfirmCoupon(barcode: string): ClearCouponLight {
  const { post, loading, error, data, response } = useFetch<FetchResponse>(`/barcode/sc/${barcode}/confirm`, {
    cachePolicy: CachePolicies.NO_CACHE,
    onTimeout: () => Bugsnag.notify(new Error('timeout')),
    onError: ({ error }) => Bugsnag.notify(error),
  });

  return {
    loading,
    error,
    post,
    data,
    response,
  };
}
