import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import { IncomingOptions, Provider as HttpProvider } from 'use-http';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { theme } from './theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { CouponPage } from './ecosystems/CouponPage';
import { getRequiredEnvironmentVariable } from './util';
import { HelmetProvider } from 'react-helmet-async';
import { NotFoundPage } from './ecosystems/NotFoundPage';
import * as luxon from 'luxon';

const consumerServer = getRequiredEnvironmentVariable('REACT_APP_CONSUMER_SERVER_URL');
const appVersion = getRequiredEnvironmentVariable('REACT_APP_VERSION');
const bugsnagApiKey = getRequiredEnvironmentVariable('REACT_APP_BUGSNAG_API_KEY');
const stage = getRequiredEnvironmentVariable('REACT_APP_STAGE');

// useParams で値を取得できる、単純に型を定義する。ドキュメンテーション。
export type PathParams = {
  couponId: string;
};
// Bugsnag クライアント
Bugsnag.start({
  apiKey: bugsnagApiKey,
  appVersion,
  releaseStage: stage,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

luxon.Settings.defaultZoneName = 'Asia/Tokyo';
luxon.Settings.defaultLocale = 'ja-JP';

function App(): React.ReactElement {
  const options: IncomingOptions = {
    headers: {
      Accept: `application/json`,
    },
  };

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <HttpProvider options={options} url={consumerServer}>
            <BrowserRouter>
              <Switch>
                <Route path="/:couponId" exact component={CouponPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </BrowserRouter>
          </HttpProvider>
        </ThemeProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;
