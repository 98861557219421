import { CachePolicies, useFetch } from 'use-http';
import { useCallback, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { DisplayBarcode, SmartCheckLightDataProps } from '@sbgift-coupon/use';

export type GetUserCouponUseCaseResult = {
  id: string;
  couponMasterId: string;
  organizationId: string;
  organizationName: string;

  available: boolean;
  applyWith: 'barcode' | 'smartCheck' | 'smartCheckLight';
  displayable: 'before' | 'display' | 'after';
  validity: 'before' | 'valid' | 'after';
  status: string;
  validityStartDateTime?: string;
  validityEndDateTime: string;

  couponName: string;
  couponCode: string;

  // 商品画像
  productImageUrl?: string;
  // ヘッダ画像
  companyImageUrl?: string;
  // バーコード画像
  barcode: DisplayBarcode;

  description?: string;
  information?: string;
  meta?: string;
  mapUrl?: string;
  telNumber?: string;

  layoutPattern: string;
  couponNameHtml: string;
  couponNameTextColor: string;
  couponNameBandColor: string;
  couponIssuerTextColor: string;
  couponIssuerBandColor: string;

  smartCheckSetting: SmartCheckLightDataProps['smartCheckSetting'];
  smartCheckImageUrl: SmartCheckLightDataProps['smartCheckImageUrl'];
  smartCheckCountDownNow: string;
  smartCheckUsedAt: string;
  smartCheckUsedOrganizationName: string;
};

export type GetCoupon = {
  loading: boolean;
  error?: Error;
  coupon?: GetUserCouponUseCaseResult;
};

export function useGetCoupon(couponId: string): GetCoupon {
  const { get, loading, error, data } = useFetch<GetUserCouponUseCaseResult>(`/coupons`, {
    cachePolicy: CachePolicies.NO_CACHE,
    onTimeout: () => Bugsnag.notify(new Error('timeout')),
    onError: ({ error }) => Bugsnag.notify(error),
  });
  const loadInitialCoupon = useCallback(async () => {
    await get(couponId);
  }, [get]);
  useEffect(() => {
    async function load(): Promise<void> {
      await loadInitialCoupon();
    }
    load();
  }, [loadInitialCoupon]);
  return {
    loading,
    error,
    coupon: data,
  };
}
