import { CachePolicies, FetchData, useFetch } from 'use-http';
import Bugsnag from '@bugsnag/js';
import { GetUserCouponUseCaseResult } from './get-coupon';

export type ClearCouponLight = {
  loading: boolean;
  error?: Error;
  post: FetchData;
};

/**
 * SmartCheckLightのクーポンを消し込む
 * @param barcode
 */
export function useForceCouponLight(barcode: string): ClearCouponLight {
  const { post, loading, error } = useFetch<GetUserCouponUseCaseResult>(`/barcode/scl/${barcode}/force`, {
    cachePolicy: CachePolicies.NO_CACHE,
    onTimeout: () => Bugsnag.notify(new Error('timeout')),
    onError: ({ error }) => Bugsnag.notify(error),
  });

  return {
    loading,
    error,
    post,
  };
}
