import React from 'react';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';

export const MasterHead: React.FC<{ metaTags?: string; couponName: string }> = (props) => {
  if (props.metaTags) {
    return <Helmet>{parse(props.metaTags)}</Helmet>;
  } else {
    return (
      <Helmet>
        <title>{props.couponName}</title>
      </Helmet>
    );
  }
};
